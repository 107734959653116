import React from "react";

// Admin pages
const AdminHome= React.lazy(()=> import('./admin/views/AdminHome'));
const AdminRoles= React.lazy(()=> import('./admin/views/AdminRoles'));
const AdminUsers= React.lazy(()=> import('./admin/views/AdminUsers'));
const AccessRights= React.lazy(()=> import('./admin/views/AccessRights'));
const AdminAudio=React.lazy(()=>import('./admin/views/AdminAudio'));
const AdminVideo=React.lazy(()=>import('./admin/views/AdminVideo'));
const AdminAddon=React.lazy(()=>import('./admin/views/AdminAddon'));
const AdminPlaylist=React.lazy(()=>import('./admin/views/AdminPlaylist'));
const AdminHelp=React.lazy(()=>import('./admin/views/AdminHelp'));
const AdminAdvertisement=React.lazy(()=>import('./admin/views/Advertisement'));
const AdminLogging=React.lazy(()=>import('./admin/views/AdminLogging'));

// User pages
const Home= React.lazy(()=>import('./views/Home'));
const Video= React.lazy(()=>import('./views/Video'));
const Audio= React.lazy(()=>import('./views/Audio'));
const UploadForm= React.lazy(()=>import('./views/UploadForm'));
const ViewAudio=React.lazy(()=>import('./views/AudioDetail'));
const VideoDetail=React.lazy(()=>import('./views/VideoDetail'))
const PlaylistDetail=React.lazy(()=>import('./views/PlaylistDetail'))
const LanguageDetail=React.lazy(()=>import('./views/LanguageDetail'))
const DisplayArtistPlaylist=React.lazy(()=>import('./views/DisplayArtistPlaylist'))
const PlayLists=React.lazy(()=>import('./library/Playlists'));
const VideoPlaylist=React.lazy(()=>import('./views/VideoPlaylist'));
const Favourites=React.lazy(()=>import('./library/Favourites'));
const UserProfile=React.lazy(()=>import('./views/UserProfile'));
const Search=React.lazy(()=>import('./views/Search'));
const PrivacyPolicy=React.lazy(()=>import('./views/PrivacyPolicy'));
const AboutUS=React.lazy(()=>import('./views/AboutUs'));
const ContactUs=React.lazy(()=>import('./views/ContactUs'));
export const routes=[
    // Admin pages
    { path:'/admin/home', name:'Home', adminElement : AdminHome },
    { path:'/admin/roles', name:'Roles', adminElement : AdminRoles },
    { path:'/admin/users', name:'Users', adminElement : AdminUsers },
    { path:'/admin/access_rights', name:'Access Rights', adminElement : AccessRights },
    { path:'/admin/audio', name:'Audio', adminElement : AdminAudio },
    { path:'/admin/video', name:'Video', adminElement : AdminVideo },
    { path:'/admin/addon', name:'Addon', adminElement : AdminAddon },
    { path:'/admin/playlist', name:'Playlist', adminElement : AdminPlaylist },
    {path:'/admin/profile/:id',name:'Profile',adminElement : UserProfile},
    { path:'/admin/help', name:'Help', adminElement : AdminHelp },
    { path:'/admin/advertisement', name:'Advertisement', adminElement : AdminAdvertisement },
    {path:'/admin/logging',name:'Logging',adminElement:AdminLogging},

    // User Pages
    { path:'/home', name:'Home', element : Home },
    { path:'/video', name:'Video', element : Video },
    { path:'/audio', name:'Audio', element : Audio },
    { path:'/upload', name:'Upload', element : UploadForm },
    { path:'/video/:id', name:'Video', element : VideoDetail },
    { path:'/audio/:id', name:'Audio', element : ViewAudio },
    {path:'/playlist/:id',name:'Playlist',element:PlaylistDetail},
    {path:'/language/:id',name:'LanguageList',element:LanguageDetail},
    {path:'/listView/:str',name:'ArtistPlaylist',element:DisplayArtistPlaylist},
    {path:'/playlists',name:'Playlists',element:PlayLists},
    {path:'/videoplaylist/:id',name:'VideoPlaylist',element:VideoPlaylist},
    {path:'/favourites',name:'Favourites',element:Favourites},
    {path:'/profile/:id',name:'Profile',element:UserProfile},
    {path:'/search',name:'Search',element:Search},
    {path:'/privacy_policy',name:'Privacy Policy',element:PrivacyPolicy},
    {path:'/about_us',name:'About Us',element:AboutUS},
    {path:'/contact_us',name:'Contact Us',element:ContactUs},
]