import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';
// user components
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import AppContent from './AppContent';

// Admin components
import AdminHeader from '../admin/components/AdminHeader';
import AdminSidebar from '../admin/components/AdminSidebar';
import AdminContent from './AdminContent'
import Feedback from '../views/Feedback';

import EnglishTranslation from "../translate/EN.json"

export const playlistData = createContext(null);
function DefaultLayout() {
    const [open, setOpen] = useState(false);
    const [menu, setmenu] = useState(true);
    const location = useLocation();
    const [playlistRecords, setPlaylistRecords] = useState([]);
    const [queueList,setQueueList]=useState([])
    const [search, setSearch] = useState();
    const [language, setLanguage] = useState({ id: '', name: '' });
    const [viewHelp, setViewHelp] = useState(false);
    const [theme,setTheme] = useState('dark')
  const [progress, setProgress] = useState(0);
  const [translation,setTranslation]=useState({"code":"EN","t":EnglishTranslation,"language":"ENGLISH"})
  const [queueListViews, setQueueListViews] = useState(false)
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const handleMediaQueryChange = (e) => {
            if (e.matches) {
                setmenu(false);
                setOpen(false);
            } else {
                setmenu(true);
                setOpen(true);
            }
        };
        mediaQuery.addEventListener('change', handleMediaQueryChange);
        if (mediaQuery.matches) {
            setmenu(false);
            setOpen(false);
        }
        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);
    useEffect(() => {
        if(location.pathname.match('/admin')) {
            document.body.style.backgroundColor = "#0a1f38";
        }
        else{
            if(theme === 'dark'){
                document.body.style.backgroundColor = "black";
            }
            else{
                document.body.style.backgroundColor = "#ede8e8";
            }
        }
        // eslint-disable-next-line
    },[location.pathname,theme])
    const handleCancel = () => {
        setViewHelp(false);
    }
    useEffect(() => {
        sessionStorage?.setItem('translation',"ENGLISH")
    },[])
    return (
        <div>
            {
                location.pathname.match('/admin') ?
                    <>
                    <playlistData.Provider value={{translation,setTranslation}}>
                        <AdminHeader menu={menu} openSideBar={() => { setmenu(!menu) }} />
                        <AdminSidebar menu={menu} openSideBar={() => { setmenu(!menu) }} />
                        <div className={`${menu ? "admin-content" : "admin-content-all"} p-2`}>
                            <AdminContent />
                        </div>
                    </playlistData.Provider>
                    </>
                    :
                    <>
                        <playlistData.Provider value={{ playlistRecords, setPlaylistRecords, search, setSearch, language, setLanguage,theme,setTheme,progress, setProgress,translation,setTranslation,queueList,setQueueList,queueListViews, setQueueListViews}}>
                            <Header OpenMenu={() => setOpen(!open)} />
                            <div className="progress" role="progressbar" aria-label="Example 5px high" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{height: '3px',position:'relative',zIndex: '1000',visibility:progress > 0 ? 'visible':'hidden'}}>
                            <div className="progress-bar" style={{width: `${progress}%`,backgroundColor:'#f06b20'}}></div>
                            </div>
                            <div>
                                <div className={`sidebar ${open ? 'open' : ''} ${theme==="dark"?"sidebarDark":"sidebarLight"}`} style={{marginBottom: sessionStorage.getItem('token') ? '35px':'0px'}}>
                                    <Sidebar handleMenu={() => setOpen(!open)}/>
                                </div>
                                <div className={(open && queueListViews) ? 'content queue open': open ? 'content open' : queueListViews? 'content queue' : 'content'}>
                                {/* {open ? 'content open' : 'content'} */}
                                    <AppContent />
                                </div>
                                {
                                    viewHelp ?
                                        <div className='helpView' style={{bottom: (playlistRecords.length > 0 || queueList.length > 0) ? '120px' : '20px'}}><Feedback handleCancel={handleCancel} /></div>
                                        :
                                        <button type="button" style={{bottom: (playlistRecords.length > 0 || queueList.length > 0) ? '120px' : '20px'}} title={translation?.t?.HELP?.HELP} className='btn btn-sm rounded-4 helpBtn' onClick={() => { setViewHelp(true) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                                            <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                            <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2" />
                                        </svg> {translation?.t?.HELP?.HELP}</button>
                                }

                            </div>
                            <Footer />
                        </playlistData.Provider>
                    </>
            }
        </div>
    )
}

export default DefaultLayout