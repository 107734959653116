import React,{ useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { swalMsg } from '../constant/Util';
import '../styles/Home.css'
import { playlistData } from '../layouts/DefaultLayout';
import Swal from 'sweetalert2';

function Sidebar({handleMenu}) {
  const location = useLocation();
  const navigate = useNavigate();
  const context=useContext(playlistData)

  const menu = [
    {
      name: context?.translation?.t?.MENU?.HOME,
      path: "/home",
      icon: <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
    },
    {
      name: context?.translation?.t?.MENU?.AUDIO,
      path: "/audio",
      icon: <path d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z" />
    },
    {
      name: context?.translation?.t?.MENU?.VIDEO,
      path: "/video",
      icon: <path d="M448 32H361.9l-1 1-127 127h92.1l1-1L453.8 32.3c-1.9-.2-3.8-.3-5.8-.3zm64 128V96c0-15.1-5.3-29.1-14-40l-104 104H512zM294.1 32H201.9l-1 1L73.9 160h92.1l1-1 127-127zM64 32C28.7 32 0 60.7 0 96v64H6.1l1-1 127-127H64zM512 192H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V192z" />
    },
  ]

  const library = [
    {
      name: context?.translation?.t?.MENU?.MY_PLAYLIST,
      path: "/playlists",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={context?.theme==="dark"? "white" :location.pathname === "/playlists"?"white":"black"} className="bi bi-music-note-list" viewBox="0 0 16 16">
        <path d="M12 13c0 1.105-1.12 2-2.5 2S7 14.105 7 13s1.12-2 2.5-2 2.5.895 2.5 2" />
        <path fillRule="evenodd" d="M12 3v10h-1V3z" />
        <path d="M11 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 16 2.22V4l-5 1z" />
        <path fillRule="evenodd" d="M0 11.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5m0-4A.5.5 0 0 1 .5 7H8a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5m0-4A.5.5 0 0 1 .5 3H8a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5" />
      </svg>
    },
    {
      name: context?.translation?.t?.MENU?.FAVORITES,
      path: "/favourites",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={context?.theme==="dark"? "white" :location.pathname === "/favourites"?"white":"black"} className="bi bi-file-music-fill" viewBox="0 0 16 16">
        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m-.5 4.11v1.8l-2.5.5v5.09c0 .495-.301.883-.662 1.123C7.974 12.866 7.499 13 7 13s-.974-.134-1.338-.377C5.302 12.383 5 11.995 5 11.5s.301-.883.662-1.123C6.026 10.134 6.501 10 7 10c.356 0 .7.068 1 .196V4.41a1 1 0 0 1 .804-.98l1.5-.3a1 1 0 0 1 1.196.98" />
      </svg>
    },
    // {
    //   name: "For you",
    //   path: "/for-you",
    //   icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-view-list" viewBox="0 0 16 16">
    //     <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2m0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2m0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14" />
    //   </svg>
    // }
  ]

  const logout = () => {
    Swal.fire({
      toast: true,
      title: context?.translation?.t?.MENU?.LOGOUT_CONFIRMATION,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: context?.translation?.t?.MENU?.YES_LOGOUT
  }).then((result) => {
      if (result.isConfirmed) {
    sessionStorage.clear();
    navigate('/home');
      }
  })
  }

  const isMobile = window.innerWidth < 768;
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    if (alert) {
      swalMsg(context?.translation?.t?.FAVORITES?.SIGN_IN_TO_CONTINUE);
      setAlert(false)
    }
    // eslint-disable-next-line
  }, [alert])
  return (
    <>
      <div className="" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        {/* nav flex-column nav-pills sidebar-items */}
        <h6 className='p-2 fw-bold menu-title'>{context?.translation?.t?.SIDEBAR?.EXPLORE}</h6>
        <div className='px-2'>
          {
            menu.map((item, index) =>
              <Link key={index} to={item.path} onClick={() => isMobile && handleMenu()} className={`nav-link mb-1 ${location.pathname === item.path ? "active" : "1"}`} title={item.name} >
                <svg xmlns="http://www.w3.org/2000/svg" fill={context?.theme==="dark"?"white":location?.pathname === item.path? "white":"black"}
                  viewBox="0 0 576 512"
                  width={15} >{item.icon}</svg>
                <span className='small'>{item.name}</span>
              </Link>
            )
          }
        </div>
        <div className='library '>
          <h6 className='p-2 fw-bold menu-title'>{context?.translation?.t?.MENU?.LIBRARY}</h6>
          <div className='px-2'>
            {
              library?.map((item, index) =>
                sessionStorage.getItem('token') ?
                  <Link key={index} to={item?.path} onClick={() => isMobile && handleMenu()} className={`nav-link mb-1 text-truncate ${location?.pathname === item?.path ? "active" : "1"} ${library?.length - 1 === index ? "addBottomSpace" : ""}`} title={item?.name}>
                    <div>{item.icon} </div>
                    <span className='small text-truncate'>{item?.name}</span>
                  </Link>
                  :
                  <div key={index} className={`nav-link mb-1 text-truncate ${library?.length - 1 === index ? "addBottomSpace" : ""}`} onClick={() =>{isMobile && handleMenu(); setAlert(true)}} title={context?.translation?.t?.MENU?.SIGN_IN_TO_VIEW} style={{ cursor: 'not-allowed' }}>
                    <div>{item?.icon} </div>
                    <span className='small text-truncate'>{item?.name}</span>
                  </div>
              )
            }
          </div>
        </div>
        <div className='logout-btn'>
        <div className={`${context?.theme==="dark"?"policy_container":"policy_container_light"} d-flex flex-wrap justify-content-evenly align-items-center`}>
        <Link to={"/about_us"} className={`${context?.theme==="dark"?"text-secondary terms_txt":"text-muted terms_txt_light"} ${location?.pathname==="/about_us"?"policy_active":""} small mb-2`}>About us</Link>
        <Link to={"/contact_us"} className={`${context?.theme==="dark"?"text-secondary terms_txt":"text-muted terms_txt_light"} ${location?.pathname==="/contact_us"?"policy_active":""} small mb-2`}>Contact us</Link>
        <Link to={"/privacy_policy"} className={`${context?.theme==="dark"?"text-secondary terms_txt":"text-muted terms_txt_light"} ${location?.pathname==="/privacy_policy"?"policy_active":""} small mb-2`}>Privacy policy</Link>
        </div>
        {sessionStorage.getItem('token') && <div>
          <button className='btn btn-sm w-100 logout_btn' onClick={logout} title={context?.translation?.t?.MENU?.LOGOUT}>
            <span className='text-truncate logout-text me-3'>{context?.translation?.t?.MENU?.LOGOUT}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={25} fill='currentcolor' className='p-1'>
              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
            </svg>
          </button>
        </div>}
        </div>
      </div>
    </>
  )
}

export default Sidebar