import React, { useEffect, useState, useContext, useRef } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import '../App.css';
import audio_thumbnail from '../assets/images/music1.jpg';
import '../styles/Home.css';
import { playlistData } from '../layouts/DefaultLayout';
import playIcon from '../assets/images/playIcon.gif';
import queueList from '../assets/images/queueList.png'
import { getAPI } from '../apiServices/ApiService';

function Footer() {
  const audioPlayerRef = useRef(null);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [audios, setAudios] = useState([]);
  const [detailList,setDetailList]=useState(false)
  const [detailView, setDetailView] = useState()
  const [action, setAction] = useState("play")
  const playlist = useContext(playlistData);
  useEffect(() => {
    if (Array.isArray(playlist?.playlistRecords)) {
      setCurrentTrackIndex(playlist?.playlistRecords?.[1] ? playlist?.playlistRecords?.[1] : playlist?.queueList?.[1] ? playlist?.queueList?.[1]: 0);
      setAudios(playlist?.playlistRecords?.[0] ? playlist?.playlistRecords?.[0] : playlist?.queueList?.length>0 ? playlist?.queueList?.[0] : []);
    }
  }, [playlist])
  const clearAudio = () => {
    setCurrentTrackIndex(0);
    setAudios([]);
    playlist?.setPlaylistRecords([]);
    playlist?.setQueueList([])
    playlist?.setQueueListViews(false)
    setDetailList(false)
    setDetailView()
    sessionStorage.removeItem('action')
    sessionStorage.removeItem('userPlaylistId')
    sessionStorage.removeItem('preSearch')
  }
  const fetchData = (currIndex) => {
    if(playlist?.playlistRecords?.[3]){
      var url=""
      if(playlist?.playlistRecords?.[3] === "userFavourite"){
        console.log("entersssss");
        url=`/userfavourite/audio?skip=${playlist?.playlistRecords?.[0]?.length}&limit=10`
      }
      else if(playlist?.playlistRecords?.[3] === "audioDetail"){
        url=`/audio/?artist_id=${playlist?.playlistRecords?.[0]?.[0]?.artist_id}&skip=${playlist?.playlistRecords?.[0]?.length}&limit=10${playlist?.language?.id ? "&language_id=" + playlist?.language?.id : ''}&status=approved`
      }
      else if(playlist?.playlistRecords?.[3] === "userPlaylist"){
        url=`/userplaylist/${sessionStorage.getItem('userPlaylistId')}?skip=${playlist?.playlistRecords?.[0]?.length}&limit=10`
      }
      else if(playlist?.playlistRecords?.[3] === "playlistDetail"){
        url=`/audio/?playlist_id=${playlist?.playlistRecords?.[0]?.[0]?.playlist_id}&skip=${playlist?.playlistRecords?.[0]?.length}&limit=10${playlist?.language?.id ? "&language_id=" + playlist?.language?.id : ''}&status=approved`
      }
      else if(playlist?.playlistRecords?.[3] === "languageDetail"){ 
        url=`/audio/?language_id=${playlist?.playlistRecords?.[0]?.[0]?.language_id}&skip=${playlist?.playlistRecords?.[0]?.length}&limit=10${playlist?.language?.id ? "&language_id=" + playlist?.language?.id : ''}&status=approved`
      }
      else if(playlist?.playlistRecords?.[3]==="category"){
        url=`/audio/?category_id=${playlist?.playlistRecords?.[0]?.[0]?.category_id}${playlist?.language?.id ? "&language_id=" + playlist?.language?.id : ''}&status=approved&skip=${playlist?.playlistRecords?.[0]?.length}&limit=10`
      }
      else if(playlist?.playlistRecords?.[3] === "search"){
        url=`/audio/?limit=10&skip=${playlist?.playlistRecords?.[0]?.length}${playlist?.playlistRecords?.[4] ? "&category_id=" + playlist?.playlistRecords?.[4] : ''}${playlist?.search ? "&search=" + playlist?.search : ''}&status=approved`
      }
      getAPI(url).then((res) => {
        if (res?.data?.status) {
          var mergedAudios
          if(playlist?.playlistRecords?.[3] === "userFavourite"){
            mergedAudios = [...playlist?.playlistRecords?.[0], ...res?.data?.data?.map((item)=>{return item?.audio})];
          }
          else if(playlist?.playlistRecords?.[3] === "audioDetail" || playlist?.playlistRecords?.[3] === "playlistDetail" || playlist?.playlistRecords?.[3] === "languageDetail" || playlist?.playlistRecords?.[3] === "category" || playlist?.playlistRecords?.[3] === "search"){
            mergedAudios = [...playlist?.playlistRecords?.[0], ...res?.data?.data];
          }
          else if(playlist?.playlistRecords?.[3] === "userPlaylist"){
            mergedAudios = [...playlist?.playlistRecords?.[0], ...res?.data?.data?.playlist_data?.map((item)=>{return item?.audio})];
          }
          const uniqueAudios = mergedAudios?.reduce((acc, audio) => {
              if (!acc.some(a => a?.id === audio?.id)) {
                  acc.push(audio);
              }
              return acc;
          }, []);
          if(playlist?.playlistRecords?.[3]==="search" && sessionStorage.getItem('preSearch')!==playlist?.search){
            //do nothing
          }else{
            playlist?.setPlaylistRecords([uniqueAudios,currIndex,playlist?.playlistRecords?.[2],playlist?.playlistRecords?.[3],playlist?.playlistRecords?.[4]]);
          }
        };
      }).catch((err) => {
        console.log(err);
      })
    }
  }
  const handleClickPrevious = () => {
    setCurrentTrackIndex((prevIndex) => prevIndex === 0 ? audios.length - 1 : prevIndex - 1);
    let index = currentTrackIndex > 0 ? currentTrackIndex - 1 : audios?.length - 1
    if(playlist?.playlistRecords?.length>0){
    playlist?.setPlaylistRecords([audios, index,playlist?.playlistRecords?.[2],playlist?.playlistRecords?.[3],playlist?.playlistRecords?.[4]]);
    }else if(playlist?.queueList?.length>0 && playlist?.queueList?.[0]?.length>0){
      playlist?.setQueueList([audios,index])
    }
  };

  const handleClickNext = () => {
    setCurrentTrackIndex((prevIndex) => prevIndex < audios.length - 1 ? prevIndex + 1 : 0);
    var index = currentTrackIndex < audios?.length - 1 ? currentTrackIndex + 1 : 0
    if(playlist?.playlistRecords?.length>0){
    playlist?.setPlaylistRecords([audios, index,playlist?.playlistRecords?.[2],playlist?.playlistRecords?.[3],playlist?.playlistRecords?.[4]]);
    }else if(playlist?.queueList?.length>0 && playlist?.queueList?.[0]?.length>0){
      playlist?.setQueueList([audios,index])
    }
    if(playlist?.playlistRecords?.[2] && (playlist?.playlistRecords?.[0]?.length < playlist?.playlistRecords?.[2])){
      fetchData(index)
    }
  };

  const handleEnd = () => {
    var index=0;
    if (currentTrackIndex === audios?.length - 1) {
      audioPlayerRef.current.audio.current.pause();
    } else {
      setCurrentTrackIndex((prevIndex) => prevIndex < audios.length - 1 ? prevIndex + 1 : 0);
      index = currentTrackIndex < audios?.length - 1 ? currentTrackIndex + 1 : 0
      if(playlist?.playlistRecords?.length>0){
      playlist?.setPlaylistRecords([audios, index,playlist?.playlistRecords?.[2],playlist?.playlistRecords?.[3],playlist?.playlistRecords?.[4]]);
    }else if(playlist?.queueList?.length>0 && playlist?.queueList?.[0]?.length>0){
      playlist?.setQueueList([audios,index])
    }
    }
    if(playlist?.playlistRecords?.[2] && (playlist?.playlistRecords?.[0]?.length < playlist?.playlistRecords?.[2])){
      fetchData(index)
    }
  };
  const handlePlay = (index) => {
    setCurrentTrackIndex(index);
    if(playlist?.playlistRecords?.length>0){
    playlist?.setPlaylistRecords([audios, index,playlist?.playlistRecords?.[2],playlist?.playlistRecords?.[3],playlist?.playlistRecords?.[4]]);
    }else if(playlist?.queueList?.length>0 && playlist?.queueList?.[0]?.length>0){
      playlist?.setQueueList([audios,index])
    }
  }
  useEffect(() => {
    if(detailView){
      setDetailView(audios?.[currentTrackIndex])
    }
    // eslint-disable-next-line
  },[audios?.[currentTrackIndex]])
  useEffect(() => {    
    if(playlist?.playlistRecords?.[2] && (playlist?.playlistRecords?.[0]?.length === playlist?.playlistRecords?.[2])){            
      if(playlist?.queueList?.length>0){        
      setAudios((preValue) => {
        const newAudios = playlist?.queueList?.[0] || [];
        const mergedAudios = [...preValue, ...newAudios];
        const uniqueAudios = mergedAudios?.reduce((acc, audio) => {
            if (!acc.some(a => a?.id === audio?.id)) {
                acc.push(audio);
            }
            return acc;
        }, []);
        return uniqueAudios;
    });
      }
    }
    else if(!playlist?.playlistRecords?.[2] && (playlist?.playlistRecords?.[0]?.length > 0)){      
      if(playlist?.queueList?.length>0){  
      setAudios((preValue) => {
        const newAudios = playlist?.queueList?.[0] || [];
        const mergedAudios = [...preValue, ...newAudios];
        const uniqueAudios = mergedAudios?.reduce((acc, audio) => {
            if (!acc.some(a => a?.id === audio?.id)) {
                acc.push(audio);
            }
            return acc;
        }, []);
        return uniqueAudios;
    });
  }
    }
    else if(!playlist?.playlistRecords?.[2] && (playlist?.playlistRecords?.length === 0)){
      if(playlist?.queueList?.length>0){
      setAudios(playlist?.queueList?.[0])
      }
    }    
    // eslint-disable-next-line
  },[playlist?.queueList,playlist])
  useEffect(() => {
    const scrollableDiv=document.getElementById('ScrollDivFooter');
      const handleScroll = () => {
      if (scrollableDiv.clientHeight + scrollableDiv.scrollTop >= scrollableDiv.scrollHeight) {
        if(playlist?.playlistRecords?.[2] && (playlist?.playlistRecords?.[0]?.length < playlist?.playlistRecords?.[2])){
          fetchData(playlist?.playlistRecords?.[1])
        }
        }
      };
      if(scrollableDiv){
          scrollableDiv.addEventListener('scroll', handleScroll);
        } 
        return () =>{if(scrollableDiv){
            scrollableDiv.removeEventListener('scroll', handleScroll);
        }}
        // eslint-disable-next-line
},[playlist,playlist?.queueListViews])
  const isMobile = window.innerWidth < 769;
  return (
    <>
      {
    playlist?.queueListViews && 
    <div className={`card queueListView`}>
      <div className='d-flex justify-content-between align-items-center p-3'>
        <h6>{playlist?.translation?.t?.FOOTER?.QUEUE}</h6>
        <span role='button' title={playlist?.translation?.t?.COMMON?.CLOSE} onClick={() => playlist?.setQueueListViews(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </span>
      </div>
      <div className='overflow-y-auto overflow-x-hidden mb-2' id="ScrollDivFooter">
        {
          audios?.map((item, index) => {
            return (
              <div className={`row p-1 align-items-center ${audios?.[currentTrackIndex]?.id === item?.id? "queue_active" : ""}`} key={index} role='button' onClick={() => handlePlay(index)}>
                <div className='col-lg-2 col-2'>
                  <img src={item?.thumbnail ? item?.thumbnail : audio_thumbnail} alt="audio" className='audioImage' height="40" width="40" />
                </div>
                <div className='col-lg-8 col-8 px-4'>
                  <div className='fw-bold description' title={item?.title}>{item?.title}</div>
                  <span className='description' title={item?.artist?.name}>{item?.artist?.name}</span>
                </div>
                <div className='col-lg-2 col-2 p-0'>
                  <span>{(audios?.[currentTrackIndex]?.id === item?.id && action === "play") && <img src={playIcon} height={40} width={40} alt="playing" />}</span>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
      }
      {
        detailList && 
        <div className="card detailView">
                <div className='d-flex justify-content-between align-items-center p-3'>
                  <h6>{playlist?.translation?.t?.AUDIO?.AUDIO_DETAIL}</h6>
                  <span role='button' title={playlist?.translation?.t?.COMMON?.CLOSE} onClick={() => { setDetailView(); playlist?.setQueueListViews(false);setDetailList(false) }}><svg xmlns="http://www.w3.org/2000/svg" width={20} fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                  </svg></span>
                </div>
                <div className='p-3 overflow-y-auto overflow-x-hidden mb-2'>
                  <div className='row'>
                    <div className='col-lg-6 text-center'>
                      <img src={detailView?.thumbnail ? detailView?.thumbnail : audio_thumbnail} alt="audio" className='rounded-4 detailViewImageFooter' />
                      <div className='fw-bold p-2'>{detailView?.title}</div>
                      <span className='p-2'>{detailView?.artist?.name}</span>
                      <div className='p-2'>{detailView?.sub_category?.subcategory_name}</div>
                      <div className='px-2'>
                      {detailView?.category?.category_name && <span className="tag selectedTitle">{detailView?.category?.category_name}</span>}
                      {detailView?.language?.name && <span className="tag selectedTitle">{detailView?.language?.name}</span>}
                      {detailView?.tags && <span className="tag selectedTitle">{detailView?.tags}</span>}
                      {detailView?.description && <div className='p-2'>{detailView?.description}</div>}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                    {
                      detailView?.lyrics ?
                      <>
                      <h6 className='fw-bold viewAllColor'>{playlist?.translation?.t?.FOOTER?.LYRICS}</h6>
                    <div className='p-2 mb-2' dangerouslySetInnerHTML={{ __html: detailView?.lyrics?.replace(/\n/g, '<br/>')}}></div></>
                    : <span className='text-secondary text-center p-2 fw-bold'>{playlist?.translation?.t?.FOOTER?.NO_LYRICS}</span>
                    }
                      </div>
                  </div>
                </div>
              </div>
      }
      <div className={audios?.length > 0 ? 'p-2' : ''} style={{ marginTop: audios?.length > 0 ? "70px" : '0px' }}>
        {
            audios?.length > 0 ?
              <div className={`fixed-bottom ${playlist?.theme==="dark" ? "audioFooterDark" : "audioFooterLight"}`}>
                {
                  isMobile? 
                  <div className='row d-flex align-items-center mt-1'>
                    <div className='col-2' role='button' onClick={() => { playlist?.setQueueListViews(false);setDetailView(audios?.[currentTrackIndex]); setDetailList(!detailList)}}>
                    <img src={audios?.[currentTrackIndex]?.thumbnail ? audios?.[currentTrackIndex]?.thumbnail : audio_thumbnail} alt="audio" className='audioImage' height={30} width={30} />
                    </div>
                    <div className='col-6' role='button' onClick={() => { playlist?.setQueueListViews(false);setDetailView(audios?.[currentTrackIndex]); setDetailList(!detailList)}}>
                    <div className='fw-bold description small'>{audios?.[currentTrackIndex]?.title}</div>
                    <span className='description small'>{audios?.[currentTrackIndex]?.artist?.name}</span>
                    </div>
                    <div className='col-4 d-flex justify-content-end align-items-baseline px-4'>
                    <>
                    <span className='text-decoration-underline lyricsTextColor fw-bold p-1' role='button' title={playlist?.translation?.t?.FOOTER?.LYRICS} onClick={() => { setDetailList(!detailList);setDetailView(audios?.[currentTrackIndex]);playlist?.setQueueListViews(false)}}>
                    {playlist?.translation?.t?.FOOTER?.LYRICS}
                      </span>
                    <span className='text-dark' role='button' title={playlist?.translation?.t?.FOOTER?.QUEUE_LIST} onClick={() => {playlist?.setQueueListViews(!playlist?.queueListViews);setDetailView();setDetailList(false)}}>
                    <img src={queueList} alt="queue" width="35" height="35" className='rounded' />
                      </span>
                      <span className={`${playlist?.theme==="dark" ? "text-light" : "text-dark"}`} role='button' title={playlist?.translation?.t?.COMMON?.CLOSE} onClick={clearAudio}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </span>
                    </>
                    </div>
                    <div className='col-12'>
                      <AudioPlayer
                      ref={audioPlayerRef}
                      autoPlay
                      src={audios?.[currentTrackIndex]?.audio_url}
                      onPlay={() => {setAction("play");sessionStorage.setItem("action","play"); const event = new Event('actionChange'); window.dispatchEvent(event)}}
                      onPause={() => {setAction("pause");sessionStorage.setItem("action","pause"); const event = new Event('actionChange'); window.dispatchEvent(event)}}
                      loop={false}
                      style={{ backgroundColor: "transparent", boxShadow: "none" }}
                      onEnded={handleEnd}
                      showSkipControls
                      showJumpControls={false}
                      onClickPrevious={handleClickPrevious}
                      onClickNext={handleClickNext}
                    />
                    </div>
                  </div>:
                  <div className='row'>
                  <div className='col-lg-4 col-3 row align-items-center d-flex' role='button' onClick={() => { setDetailList(!detailList);setDetailView(audios?.[currentTrackIndex]);playlist?.setQueueListViews(false)}}>
                    <div className='col-lg-3'>
                      <img src={audios?.[currentTrackIndex]?.thumbnail ? audios?.[currentTrackIndex]?.thumbnail : audio_thumbnail} alt="audio" className='audioImage' height={60} width={"100%"} />
                    </div>
                    <div className='col-lg-9'>
                      <div className='fw-bold description'>{audios?.[currentTrackIndex]?.title}</div>
                      <span className='description'>{audios?.[currentTrackIndex]?.artist?.name}</span>
                    </div>
                  </div>
                  <div className='col-lg-4 d-flex justify-content-center align-items-center'>
                    <AudioPlayer
                      ref={audioPlayerRef}
                      autoPlay
                      src={audios?.[currentTrackIndex]?.audio_url}
                      onPlay={() => {setAction("play");sessionStorage.setItem("action","play"); const event = new Event('actionChange'); window.dispatchEvent(event)}}
                      onPause={() => {setAction("pause");sessionStorage.setItem("action","pause"); const event = new Event('actionChange'); window.dispatchEvent(event)}}
                      loop={false}
                      style={{ backgroundColor: "transparent", boxShadow: "none" }}
                      onEnded={handleEnd}
                      showSkipControls
                      showJumpControls={false}
                      onClickPrevious={handleClickPrevious}
                      onClickNext={handleClickNext}
                    />
                  </div>
                  <div className='col-lg-4 d-flex justify-content-end align-items-center'>
                    <div className='me-3'>
                      <span className='p-5 text-decoration-underline lyricsTextColor fw-bold'>
                      <span  role='button' title={playlist?.translation?.t?.FOOTER?.LYRICS} onClick={() => { setDetailList(!detailList);setDetailView(audios?.[currentTrackIndex]);playlist?.setQueueListViews(false)}}>{playlist?.translation?.t?.FOOTER?.LYRICS}</span>
                      </span>
                      <span role='button' title={playlist?.translation?.t?.FOOTER?.QUEUE_LIST} onClick={() => {playlist?.setQueueListViews(!playlist?.queueListViews);setDetailView();setDetailList(false)}}>
                        <img src={queueList} alt="queue" width="50" height="50" className='rounded' />
                      </span>
                      <span className={`p-2 ${playlist?.theme==="dark" ? "text-white" : 'text-dark'}`} role='button' title={playlist?.translation?.t?.COMMON?.CLOSE} onClick={clearAudio}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={20} fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
                      </span>
                    </div>
                  </div>
                </div>
                }
                
              </div>
              : ""
        }
      </div>
    </>
  )
}

export default Footer