import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAPI } from '../../apiServices/ApiService';
import userProfile from '../../assets/images/user_icon.png';
import '../styles/admin.css';
import { profileName } from '../../constant/Util';
import Swal from 'sweetalert2';
function AdminSidebar({ menu, openSideBar }) {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [rights, setRights] = useState([])
    const navigate = useNavigate();
    const getRights = () => {
        getAPI('/admin/userrights/').then((res) => {
            if (res?.data?.status) {
                let arr = []
                // eslint-disable-next-line
                res?.data?.data?.modules?.map((item) => {
                    let check = item?.features?.some((data) => { return data?.status === true })
                    if (check) {
                        arr.push({ module_id: item?.module_id, name: item?.module_name })
                    }
                })
                setRights(arr)
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const getUserDetails = () => {
        getAPI('/admin/currentuser/').then((res) => {
            if (res?.data?.status) {
                setUser(res?.data?.data)
            } else {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: "Session expired, please login again!",
                    showConfirmButton: false,
                    timer: 1500
                })
                sessionStorage.clear();
                navigate('/login');
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        getUserDetails()
        getRights()
        // eslint-disable-next-line
    }, [])

    const viewProfile = () => {
        navigate(`/admin/profile/${user?.user_id}`);
    }

    const sidemenu = [
        { name: 'Home', path: '/admin/home', icon: <i className='fas fa-home'></i> },
        {
            name: 'Audio', path: '/admin/audio', icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-volume-up" viewBox="0 0 16 16">
                <path d="M11.536 14.01A8.47 8.47 0 0 0 14.026 8a8.47 8.47 0 0 0-2.49-6.01l-.708.707A7.48 7.48 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303z" />
                <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.48 5.48 0 0 1 11.025 8a5.48 5.48 0 0 1-1.61 3.89z" />
                <path d="M10.025 8a4.5 4.5 0 0 1-1.318 3.182L8 10.475A3.5 3.5 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.5 4.5 0 0 1 10.025 8M7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11" />
            </svg>
        },
        {
            name: 'Video', path: '/admin/video', icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-video" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
            </svg>
        },
        { name: 'Playlist', path: '/admin/playlist', icon: <i className='fas fa-list'></i> },
        { name: 'Addon', path: '/admin/addon', icon: <i className='fas fa-plus'></i> },
        {
            name: 'Help', path: '/admin/help', icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
            </svg>
        },
        {
            name: 'Advertisement', path: '/admin/advertisement', icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-badge-ad" viewBox="0 0 16 16">
                <path d="m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11zm1.503-4.852.734 2.426H4.416l.734-2.426zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z" />
                <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" />
            </svg>
        },
        { name: 'Users', path: '/admin/users', icon: <i className='fas fa-users'></i> },
        { name: 'Roles', path: '/admin/roles', icon: <i className='fas fa-user-tag'></i> },
        {
            name: 'Access Rights', path: '/admin/access_rights',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-universal-access-circle" viewBox="0 0 16 16">
                <path d="M8 4.143A1.071 1.071 0 1 0 8 2a1.071 1.071 0 0 0 0 2.143Zm-4.668 1.47 3.24.316v2.5l-.323 4.585A.383.383 0 0 0 7 13.14l.826-4.017c.045-.18.301-.18.346 0L9 13.139a.383.383 0 0 0 .752-.125L9.43 8.43v-2.5l3.239-.316a.38.38 0 0 0-.047-.756H3.379a.38.38 0 0 0-.047.756Z" />
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Z" />
            </svg>
        },
        {
            name: 'System logs', path: '/admin/logging',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-diff" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4m-2.5 6.5A.5.5 0 0 1 6 10h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5"/>
            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
          </svg>
        },
    ]
    const verifyRights = (data) => {
        if (data?.name === 'Home') return true
        if(data?.name==='System logs'){
            let check= rights?.some((item)=>{return item?.name?.toLowerCase()==='logs'})
            return check
        }
        if (data?.name === "Addon") {
            let x = rights?.some((item) => { return (item?.name === "Artists" || item?.name === "Languages" || item?.name === "Category") })
            if (x) return true
        }
        let check = rights?.some((item) => { return item?.name?.toLowerCase() === data?.name?.toLowerCase() })
        return check
    }
    useEffect(() => {
        const handleUserUpdated = (e) => {
            getUserDetails();
        };
        window.addEventListener("userUpdated", handleUserUpdated);
        return () => {
            window.removeEventListener("userUpdated", handleUserUpdated);
        };
        // eslint-disable-next-line
    }, []);
    return (
        <div>
            <aside className={`${menu ? "main-sidebar p-1" : "sidebar-closed"}`} id="sidebar" >
                {
                    menu &&
                    <div onClick={openSideBar} className='text-end p-2' role='button' title='Close'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </div>
                }
                <div className='d-flex justify-content-center position-relative'>
                    {
                        user?.profile ? <img src={`${user?.profile}?random=${Math.random().toFixed(2)}`} className="avatar_profile rounded-circle" alt="User" /> :
                            user?.fullname ? <div className="avatar_profile d-flex align-items-center justify-content-center text-white" style={{ height: "100px", backgroundColor: "#112c4c", borderRadius: "50%", fontSize: "30px", fontWeight: "bold" }} >{profileName(user?.fullname)}</div> : <img src={userProfile} className="avatar_profile" alt="User" />
                    }
                    <span className="position-absolute adminEdit" title='Edit Profile' onClick={viewProfile}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={18} fill="white" class="bi bi-person-gear" viewBox="0 0 16 16">
  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
</svg>
                    </span>
                </div>

                {
                    user?.fullname &&
                    <>
                        {/* User Name */}
                        <div className='d-flex align-items-center justify-content-center mt-2 p-2'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                </svg>
                            </div>
                            <div>
                                <p className='ms-2 fw-bold m-0' style={{ overflowWrap: 'anywhere', fontSize: 'larger' }}>{user?.fullname}</p>
                            </div>
                        </div>
                        {/* User mail ID */}
                        <div className='d-flex align-items-center justify-content-center p-2 pt-1'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
                                    <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                                    <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791" />
                                </svg>
                            </div>
                            <div>
                                <p className='ms-2 fw-bold m-0' style={{ overflowWrap: 'anywhere', fontSize: 'larger' }}>{user?.email_id}</p>
                            </div>
                        </div>
                    </>
                }

                <nav className="mt-2 p-2" >
                    <ul className="nav nav-pills" role="menu" >
                        {
                            sidemenu?.map((item, index) => (
                                verifyRights(item) &&
                                <Link to={item.path} key={index} className='w-100 mb-2 text-decoration-none'>
                                    <li key={index} className={`nav-link-sidebar w-100 mb-2 text-decoration-none px-3 py-2 ${location.pathname === item.path ? "active" : ""}`} >
                                        <div className='menu-item' >
                                            <span className='me-3'>{item.icon}</span>{item.name}
                                        </div>
                                    </li>
                                </Link>
                            ))
                        }
                    </ul>
                </nav>

            </aside >
        </div >
    )
}

export default AdminSidebar