import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import DefaultLayout from './layouts/DefaultLayout';
import Login from './views/Login';
import Register from './views/Register';
import RegistrationVerify from './views/RegistrationVerify';

function App() {

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path='/' element={<Navigate to='/home' />} />
          <Route path='/admin' element={<Navigate to='/admin/home' />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/registration_verify' element={<RegistrationVerify />} />
          <Route path='*' element={<DefaultLayout />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
