import React, { useContext ,useState} from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import { addUpdateAPI } from '../apiServices/ApiService'
import Swal from 'sweetalert2'
import { playlistData } from '../layouts/DefaultLayout'
import { isValidPhoneNumber } from "libphonenumber-js";

function Feedback({ handleCancel }) {
    const context=useContext(playlistData)
    const schema = yup.object().shape({
        fullname: yup.string().required(context?.translation?.t?.HELP?.NAME_IS_REQUIRED),
        email: yup.string().required(context?.translation?.t?.HELP?.EMAIL_IS_REQUIRED).matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            context?.translation?.t?.HELP?.INVALID_EMAIL
          ).max(100,context?.translation?.t?.LOGIN?.EMAIL_MAX_LENGTH),
        phone: yup.string().optional(),
        phone_code: yup.string(),
        description: yup.string().required(context?.translation?.t?.HELP?.DESCRIPTION_IS_REQUIRED),
        attachment: yup.mixed().notRequired()
    })
    const { register, handleSubmit, reset, setValue, setError, clearErrors, formState: { errors } } = useForm({ resolver: yupResolver(schema) })
    const handleClose = () => {
        reset()
        handleCancel()
    }
    const [phoneErr,setPhoneErr]=useState({error:false,msg:""})
    const handlePhoneChange = (phone, country) => {
        let number = phone?.slice(country?.dialCode?.length)
        var isValidNumber=null
        if(number){
            if(number?.split('')?.every((item) => item==="0")){
                isValidNumber= false
            }else if(number?.split('')?.[0]==="0"){
                isValidNumber= false
            }else{
                isValidNumber = isValidPhoneNumber(phone, country?.countryCode?.toUpperCase());
            }
        }
        if(isValidNumber===false){            
            setPhoneErr({error:true,msg:context?.translation?.t?.HELP?.INVALID_PHONE})
        }else{
            setPhoneErr({error:false,msg:""})
        }
        setValue('phone_code', country?.dialCode);
        setValue('phone', number);
    }
    const onSubmit = (data) => {
        if(phoneErr?.error){return}
        if (data?.attachment[0]) {
            //check file type
            if (data?.attachment[0]?.type !== "image/jpeg" && data?.attachment[0]?.type !== "image/jpg" && data?.attachment[0]?.type !== "image/png") {
                setError("attachment", { type: "custom", message: context?.translation?.t?.HELP?.FILES_ARE_ALLOWED })
                return
            } else {
                clearErrors("attachment")
            }
        }
        const sendData = {
            "fullname": data?.fullname,
            "email": data?.email,
            "phone_number": data?.phone,
            "country_code": data?.phone_code,
            "description": data?.description,
        }
        const fd = new FormData()
        if (data?.attachment[0]) {
            fd.append("attachment", data?.attachment[0])
        }
        fd.append("data", JSON.stringify(sendData))
        addUpdateAPI("POST", "/help/", fd).then((res) => {
            if (res?.data?.status) {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: context?.translation?.t?.HELP?.FEEDBACK_SENT_SUCCESSFULLY,
                    showConfirmButton: false,
                    timer: 1500
                })
                handleClose()
            } else {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: res?.data?.detail,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <div className={`animationFadeIn card p-3 rounded-4 ${context?.theme==="dark"?"helpCard":"helpCardLight"}`}>
            <p className='text-center fw-bold'>{context?.translation?.t?.HELP?.HAVING_ANY_PROBLEM}</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder={context?.translation?.t?.HELP?.NAME} className={`form-control ${context?.theme==="dark"?"helpInput":""}`} {...register('fullname',{setValueAs: (value) => value?.trimStart()})} />
                {errors.fullname && <span className='text-danger'>{errors.fullname.message}</span>}
                <input type="email" placeholder={context?.translation?.t?.HELP?.EMAIL} className={`form-control mt-2 ${context?.theme==="dark"?"helpInput":""}`} {...register('email',{setValueAs: (value) => value?.trimStart()})} />
                {errors.email && <span className='text-danger'>{errors.email.message}</span>}
                <PhoneInput
                    className='mt-2'
                    country={'in'}
                    enableSearch
                    placeholder={context?.translation?.t?.HELP?.PHONE_NUMBER}
                    inputStyle={{background:"transparent",color:context?.theme==="dark"?"white":"black",width:"265px" }}
                    onChange={(phone, country) => handlePhoneChange(phone, country)}/>
                {phoneErr?.error && <span className='text-danger'>{phoneErr?.msg}</span>}
                <textarea placeholder={context?.translation?.t?.HELP?.DESCRIBE_YOUR_PROBLEM} className={`form-control mt-2 ${context?.theme==="dark"?"helpInput":""}`} {...register('description',{setValueAs: (value) => value?.trimStart()})} />
                {errors.description && <span className='text-danger'>{errors.description.message}</span>}
                <input type="file" accept="image/*" placeholder={context?.translation?.t?.HELP?.ATTACHMENT} className={`form-control mt-2 ${context?.theme==="dark"?"helpInput":""}`} {...register('attachment')} style={{color:"white"}}/>
                {errors.attachment && <span className='text-danger'>{errors.attachment.message}</span>}
                <div className='d-flex justify-content-end mt-3'>
                    <button type='button' className='btn btn-secondary btn-sm me-2 rounded-4' onClick={() => handleClose()}><small>{context?.translation?.t?.COMMON?.CLOSE}</small></button>
                    <button type='submit' className='btn actionBtn btn-sm rounded-4'><small>{context?.translation?.t?.HELP?.SEND}</small></button>
                </div>
            </form>
        </div>
    )
}

export default Feedback