import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { addUpdateAPI } from '../apiServices/ApiService';
// import bg1 from '../assets/videos/login_bg.mp4';
import { logo,handleGlobalLang } from '../constant/Util';
import Swal from 'sweetalert2';

function Login() {
  const [translate]=useState(handleGlobalLang(sessionStorage.getItem('translation') ? sessionStorage.getItem('translation'): "ENGLISH"));  
  const schema = yup.object().shape({
    email: yup.string().required(translate?.t?.LOGIN?.EMAIL_IS_REQUIRED).matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      translate?.t?.LOGIN?.INVALID_EMAIL
    ).max(100,translate?.t?.LOGIN?.EMAIL_MAX_LENGTH),
    password: yup.string().required(translate?.t?.LOGIN?.PASSWORD_IS_REQUIRED).max(16,translate?.t?.LOGIN?.PWD_MAX_LENGTH),
  });
  const sendOTPSchema=yup.object().shape({
    email: yup.string().required(translate?.t?.LOGIN?.EMAIL_IS_REQUIRED).matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      translate?.t?.LOGIN?.INVALID_EMAIL
    ).max(100,translate?.t?.LOGIN?.EMAIL_MAX_LENGTH),
  });
  const verifyOTPSchema=yup.object().shape({
    otp:yup.string().required(translate?.t?.LOGIN?.OTP_REQUIRED).min(6,translate?.t?.LOGIN?.OTP_LEN).max(6,translate?.t?.LOGIN?.OTP_LEN).matches(/^[0-9]{6}$/,translate?.t?.LOGIN?.OTP_VALID),
    password: yup.string().required(translate?.t?.LOGIN?.PASSWORD_IS_REQUIRED)
      .min(6, translate?.t?.LOGIN?.PWD_LEN)
      .matches(/[A-Z]/, translate?.t?.LOGIN?.PWD_UPPER)
      .matches(/[a-z]/, translate?.t?.LOGIN?.PWD_LOWER)
      .matches(/\d/, translate?.t?.LOGIN?.PWD_NUM)
      .matches(/[!@#$%^&*(),.?":{}|<>]/, translate?.t?.LOGIN?.PWD_SPECIAL).max(16,translate?.t?.LOGIN?.PWD_MAX_LENGTH),
    confirmPassword: yup.string()
      .required(translate?.t?.LOGIN?.CONFIRM_PWD)
      .test('password-match', translate?.t?.LOGIN?.PWD_MATCH, function(value) {
          const { password } = this.parent;
          return !password || value === password; // only check match if password is provided
      }),
  })
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState('')
  const [view, setView] = useState(false)
const [forgotView, setForgotView] = useState({status:false,sendotp:false,verifyotp:false})
const [sentEmail,setSentEmail]=useState()
const [viewpwd, setViewPwd] = useState(false);
  const [confirmView, setConfirmView] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const {register: register1,handleSubmit:handleSubmit1,formState: { errors:errors1 },reset:reset1}=useForm({resolver: yupResolver(sendOTPSchema)})
  const {register: register2,handleSubmit:handleSubmit2,formState: { errors:errors2 },reset:reset2}=useForm({resolver: yupResolver(verifyOTPSchema)})
  const onSubmit = (data) => {
    setLoading(true)
    const formData = new FormData();
    formData.append('username', data?.email);
    formData.append('password', data?.password);
    addUpdateAPI('POST', '/admin/login/', formData).then((res) => {
      if (res.data.status) {
        if (res.data.admin) {
          navigate('/admin/home');
        } else {
          navigate('/home');
        }
        sessionStorage.setItem('token', res.data.access_token);
      } else {
        setErrorMsg(res.data.detail);
      }

    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    })
  }

const handleBack=()=>{
  setForgotView({status:false,sendotp:false,verifyotp:false})
  setSentEmail()
  reset1()
  reset2()
  setViewPwd(false)
  setConfirmView(false)
  setLoading(false)
}
const sendOTP=(data)=>{
  setLoading(true)
  addUpdateAPI('POST',`/admin/forgot_password/?email=${data?.email}`).then((res)=>{
    setLoading(false)
    if(res.data.status){
      Swal.fire({
        toast: true,
        icon: 'success',
        text: res.data.detail,
        showConfirmButton: false,
        timer: 1500
      })
  setSentEmail(data?.email)
  setForgotView({...forgotView,sendotp:false,verifyotp:true})
    }else{
      Swal.fire({
        toast: true,
        icon: 'error',
        text: res.data.detail,
        showConfirmButton: false,
        timer: 1500
      })
    }
  }).catch((err)=>{
    setLoading(false)
    console.log(err)
  })
}
const verifyOTP=(data)=>{
  setLoading(true)
 var value={
    "email":sentEmail,
    "otp":data?.otp,
    "password":data?.password
  }
  addUpdateAPI('POST',`/admin/reset_password/`,value).then((res)=>{
    setLoading(false)
    if(res.data.status){
      Swal.fire({
        toast: true,
        icon: 'success',
        text: res.data.detail,
        showConfirmButton: false,
        timer: 1500
      })
      handleBack()
    }else{
      Swal.fire({
        toast: true,
        icon: 'error',
        text: res.data.detail,
        showConfirmButton: false,
        timer: 1500
      })
    }

  }).catch((err)=>{
    setLoading(false)
    console.log(err)
  })  
}
const handlesendOTPBack=()=>{
  setForgotView({...forgotView,sendotp:true,verifyotp:false})
  setSentEmail()
  reset1()
  reset2()
  setViewPwd(false)
  setConfirmView(false)
}
const exceptThisSymbols = ["e", "E", "+", "-", "."]
const handleInput = (e) => {
  const value = e.target.value;
  if (value.length > 6) {
    e.target.value = value.slice(0, 6);
  }
};
// const isMobile = window.innerWidth < 769;
  return (
    <div className="container-fluid1 d-flex justify-content-center align-items-center vh-100 bg-image">
      {/* <div className='video-container'><video src={bg1} autoPlay muted loop className={`${isMobile ? '' : "bg-video"}`} /></div> */}
      {
        forgotView?.status ? 
        <div className="login-form bg-light p-5 rounded bg-transparent position-absolute animationFadeIn">
        {logo("black")}
        {
          forgotView?.sendotp ?
        <form onSubmit={handleSubmit1(sendOTP)}>
        <div className='text-center py-2 text-muted'><span>{translate?.t?.LOGIN?.FORGOT_PWD}</span></div>
        <div className='mb-3 mt-2'>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder={translate?.t?.LOGIN?.EMAIL_ID}
                aria-label="Email"
                aria-describedby="Email"
                {...register1('email')}
              />
              <span className="input-group-text" id="Email">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
              </span>
            </div>
            {errors1.email && <div className="text-danger small">{errors1.email.message}</div>}
          </div> 
        <div className="text-center">
            <button type='button' className='btn btn-sm rounded btn-secondary' onClick={()=>{handleBack()}}>{translate?.t?.LOGIN?.BACK}</button>
            <button type="submit" className="btn rounded btn-sm ms-3 signInBtn" title="Login" disabled={loading}>{loading?<i className="fas fa-spinner fa-pulse"></i>:""} {translate?.t?.LOGIN?.SEND_OTP}</button>
          </div>
        </form>:''
        }
        {
          forgotView?.verifyotp ?
          <form onSubmit={handleSubmit2(verifyOTP)}>
            <div className='text-center py-2 text-muted'><span>{translate?.t?.LOGIN?.ENTER_OTP_SENT_EMAIL} <span className='text-primary' role='button' onClick={()=>{handlesendOTPBack()}}>{sentEmail}</span></span></div>
            <div className='mb-3 mt-2'>
              <div className="input-group">
                  <input type="number" className="form-control form-control-sm" placeholder={translate?.t?.LOGIN?.ENTER_OTP} onInput={handleInput} onKeyDown={(e)=>{exceptThisSymbols.includes(e.key) && e.preventDefault()}} aria-label="OTP" {...register2('otp')} />
                  <span className="input-group-text" id="OTP">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-123" viewBox="0 0 16 16">
  <path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75z"/>
</svg>
                  </span>
              </div>
              {errors2.otp && <p className="text-danger small">{errors2.otp.message}</p>}
          </div>
            <div className='mb-3 mt-2'>
              <div className="input-group">
                  <input type={viewpwd ? "text" : "password"} className="form-control form-control-sm" autoComplete='new-password' placeholder={translate?.t?.LOGIN?.PWD} aria-label="Password" {...register2('password')} />
                  <span className="input-group-text" id="Password" onClick={() => setViewPwd(!viewpwd)} role='button'>
                      {viewpwd ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                          </svg>
                      ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                              <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                              <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                          </svg>
                      )}
                  </span>
              </div>
              {errors2.password && <p className="text-danger small">{errors2.password.message}</p>}
          </div>
          <div className='mb-3 mt-2'>
            <div className="input-group">
                <input type={confirmView ? "text" : "password"} className="form-control form-control-sm" placeholder={translate?.t?.LOGIN?.CONFIRM_PWD_PLACEHOLDER} aria-label="Confirm Password" {...register2('confirmPassword')} />
                <span className="input-group-text" id="Confirm Password" onClick={() => setConfirmView(!confirmView)} role='button'>
                    {confirmView ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                            <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                        </svg>
                    )}
                </span>
            </div>
            {errors2.confirmPassword && <p className="text-danger small">{errors2.confirmPassword.message}</p>}
        </div>
            <div className="text-center">
            <button type='button' className='btn btn-sm rounded btn-secondary' onClick={()=>{handleBack()}}>{translate?.t?.LOGIN?.BACK}</button>
            <button type="submit" className="btn rounded btn-sm ms-3 signInBtn" title="Login" disabled={loading}>{ loading ? <i className="fas fa-spinner fa-pulse"></i> : ""} {translate?.t?.LOGIN?.SUBMIT}</button>
          </div>
          </form>:""
        }
        <div className="text-center mt-2">
        {translate?.t?.LOGIN?.DONT_HAVE_AN_ACCOUNT} <Link className="text-decoration-none fw-bold lyricsTextColor" title={translate?.t?.LOGIN?.REGISTER} to={'/register'}>{translate?.t?.LOGIN?.REGISTER_NOW}</Link>
          </div>
        </div>
        :
      <div className="login-form bg-light p-5 rounded bg-transparent position-absolute animationFadeIn">
        <p className='text-danger text-center mt-0'>{errorMsg}</p>
        {logo('black')}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-3 mt-2'>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder={translate?.t?.LOGIN?.EMAIL_ID}
                aria-label="Email"
                aria-describedby="Email"
                {...register('email')}
              />
              <span className="input-group-text" id="Email">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
              </span>
            </div>
            {errors.email && <div className="text-danger small">{errors.email.message}</div>}
          </div>
          <div className='mb-3'>
            <div className="input-group">
              <input
                type={view ? 'text' : 'password'}
                className="form-control form-control-sm"
                autoComplete='new-password'
                placeholder={translate?.t?.LOGIN?.PWD}
                aria-label="Password"
                aria-describedby="Password"
                {...register('password')}
              />
              <span className="input-group-text" id="Password" onClick={() => setView(!view)} role="button">
                {view ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                  </svg>
                )}
              </span>
            </div>
            {errors.password && <div className="text-danger small">{errors.password.message}</div>}
          </div>
          <div className="text-center">
            <Link className="btn btn-secondary rounded btn-sm " title={translate?.t?.LOGIN?.BACK} to={'/home'}>{translate?.t?.LOGIN?.BACK}</Link>
            <button type="submit" className="btn rounded btn-sm ms-3 signInBtn" title={translate?.t?.LOGIN?.LOGIN}  disabled={loading}>{loading?<i className="fas fa-spinner fa-pulse"></i>:""} {translate?.t?.LOGIN?.LOGIN}</button>
          </div>
        </form>
          <div className="text-center mt-2">
          {translate?.t?.LOGIN?.DONT_HAVE_AN_ACCOUNT} <Link className="text-decoration-none fw-bold lyricsTextColor" title={translate?.t?.LOGIN?.REGISTER} to={'/register'}>{translate?.t?.LOGIN?.REGISTER_NOW}</Link>
          </div>
          <div className='text-center mt-2'>
            <div className='lyricsTextColor text-decoration-underline' role='button' title='Forgot password' onClick={()=>{setForgotView({...forgotView,status:true,sendotp:true})}}> {translate?.t?.LOGIN?.FORGOT}</div>
          </div>
      </div>
      }
    </div>
  )
}

export default Login